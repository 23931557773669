import { IconStyle } from '@/config-schema/rewards-central-config'; 
export const fontawesomeIconMap: Map<IconStyle, string> = new Map(); 
fontawesomeIconMap.set('duotone', '/assets/icon/sprites/output/duotone.6d25a3.svg'); 
fontawesomeIconMap.set('thin', '/assets/icon/sprites/output/thin.8eb21c.svg'); 
fontawesomeIconMap.set('light', '/assets/icon/sprites/output/light.f8731f.svg'); 
fontawesomeIconMap.set('regular', '/assets/icon/sprites/output/regular.06a2d4.svg'); 
fontawesomeIconMap.set('solid', '/assets/icon/sprites/output/solid.399930.svg'); 
fontawesomeIconMap.set('sharp-duotone-solid', '/assets/icon/sprites/output/sharp-duotone-solid.90afb2.svg'); 
fontawesomeIconMap.set('sharp-light', '/assets/icon/sprites/output/sharp-light.6ff729.svg'); 
fontawesomeIconMap.set('sharp-regular', '/assets/icon/sprites/output/sharp-regular.dd8255.svg'); 
fontawesomeIconMap.set('sharp-solid', '/assets/icon/sprites/output/sharp-solid.021050.svg'); 
fontawesomeIconMap.set('sharp-thin', '/assets/icon/sprites/output/sharp-thin.05ccaf.svg')